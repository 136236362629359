import classNames from "classnames"
import React from "react"
import classes from "./Col.module.css"

export interface CustomColProps {
  children?: any
  className?: string
  style?: React.CSSProperties
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  hidden?: boolean
}

export const CustomCol = ({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  hidden,
}: CustomColProps) => {
  return (
    <div
      className={classNames(
        classes.col,
        {
          [classes.gridXs0]: xs === 0,
          [classes.gridXs1]: xs === 1,
          [classes.gridXs2]: xs === 2,
          [classes.gridXs3]: xs === 3,
          [classes.gridXs4]: xs === 4,
          [classes.gridXs5]: xs === 5,
          [classes.gridXs6]: xs === 6,
          [classes.gridXs7]: xs === 7,
          [classes.gridXs8]: xs === 8,
          [classes.gridXs9]: xs === 9,
          [classes.gridXs10]: xs === 10,
          [classes.gridXs11]: xs === 11,
          [classes.gridXs12]: xs === 12,
          [classes.gridSm1]: sm === 1,
          [classes.gridSm2]: sm === 2,
          [classes.gridSm3]: sm === 3,
          [classes.gridSm4]: sm === 4,
          [classes.gridSm5]: sm === 5,
          [classes.gridSm6]: sm === 6,
          [classes.gridSm7]: sm === 7,
          [classes.gridSm8]: sm === 8,
          [classes.gridSm9]: sm === 9,
          [classes.gridSm10]: sm === 10,
          [classes.gridSm11]: sm === 11,
          [classes.gridSm12]: sm === 12,
          [classes.gridMd1]: md === 1,
          [classes.gridMd2]: md === 2,
          [classes.gridMd3]: md === 3,
          [classes.gridMd4]: md === 4,
          [classes.gridMd5]: md === 5,
          [classes.gridMd6]: md === 6,
          [classes.gridMd7]: md === 7,
          [classes.gridMd8]: md === 8,
          [classes.gridMd9]: md === 9,
          [classes.gridMd10]: md === 10,
          [classes.gridMd11]: md === 11,
          [classes.gridMd12]: md === 12,
          [classes.gridLg1]: lg === 1,
          [classes.gridLg2]: lg === 2,
          [classes.gridLg3]: lg === 3,
          [classes.gridLg4]: lg === 4,
          [classes.gridLg5]: lg === 5,
          [classes.gridLg6]: lg === 6,
          [classes.gridLg7]: lg === 7,
          [classes.gridLg8]: lg === 8,
          [classes.gridLg9]: lg === 9,
          [classes.gridLg10]: lg === 10,
          [classes.gridLg11]: lg === 11,
          [classes.gridLg12]: lg === 12,
          [classes.gridXl1]: xl === 1,
          [classes.gridXl2]: xl === 2,
          [classes.gridXl3]: xl === 3,
          [classes.gridXl4]: xl === 4,
          [classes.gridXl5]: xl === 5,
          [classes.gridXl6]: xl === 6,
          [classes.gridXl7]: xl === 7,
          [classes.gridXl8]: xl === 8,
          [classes.gridXl9]: xl === 9,
          [classes.gridXl10]: xl === 10,
          [classes.gridXl11]: xl === 11,
          [classes.gridXl12]: xl === 12,
          [classes.hidden]: hidden,
        },
        className
      )}
    >
      {children}
    </div>
  )
}
