import { getResponsiveStyle } from "../../../../atoms/functions"
import { GridItemProps } from "./types"

export const getStyles = ({
  order,
  rowStart,
  rowEnd,
  rowSpan,
  columnStart,
  columnEnd,
  columnSpan,
}: GridItemProps) => {
  return getResponsiveStyle({
    mapFunction: (prop, values) => {
      if (values.order != null && prop === "o") {
        return values.order.toString()
      }
      if (values.rowStart != null && prop === "rs") {
        return values.rowStart
      }
      if (values.rowEnd != null && prop === "re") {
        return values.rowEnd
      }
      if (values.rowSpan != null && (prop === "rs" || prop == "re")) {
        return "span " + values.rowSpan.toString()
      }
      if (values.columnStart != null && prop === "cs") {
        return values.columnStart
      }
      if (values.columnEnd != null && prop === "ce") {
        return values.columnEnd
      }
      if (values.columnSpan != null && (prop === "cs" || prop == "ce")) {
        return "span " + values.columnSpan.toString()
      }

      return null
    },
    values: {
      order,
      rowStart,
      rowEnd,
      rowSpan,
      columnStart,
      columnEnd,
      columnSpan,
    },
    propertyNames: ["o", "cs", "ce", "rs", "re"],
  })
}
