import { Row } from "@common/frontend/ui/layout"
import { setCssVar } from "@common/frontend/ui/utils"
import React from "react"
import classes from "./SplashScreen.module.css"

export interface SplashScreenProps {
  image?: string
  children: any
}

const DEFAULT_IMG = "url(/img/login_texture.svg)"

const SplashScreen = ({ image, children }: SplashScreenProps) => {
  return (
    <Row.Container
      noGutters
      h100
      style={{
        ...setCssVar("--bg-image", image ?? DEFAULT_IMG),
      }}
      className="bg-paper"
    >
      <Row.Col xs={0} md={5} className={classes.bg}></Row.Col>
      <Row.Col xs={12} md={7}>
        <div
          className={`relative h-100 flex justify-center items-center ${classes.formCol}`}
        >
          <div className={`block desk:hidden ${classes.formBgTop}`}></div>
          <div className={classes.form}>{children}</div>
          <div className={`block desk:hidden ${classes.formBgBottom}`}></div>
        </div>
      </Row.Col>
    </Row.Container>
  )
}

export default SplashScreen
