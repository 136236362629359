import { getResponsiveStyle } from "../../../../atoms/functions"
import { GridContainerProps } from "./types"

export const getStyles = ({
  columns,
  itemsMinWidth,
  rowGap,
  columnGap,
}: GridContainerProps) => {
  return getResponsiveStyle({
    mapFunction: (prop, values) => {
      if (values.columns != null && prop === "c") {
        return values.columns.toString()
      }
      if (values.itemsMinWidth != null && prop === "mw") {
        return values.itemsMinWidth.toString()
      }
      if (values.rowGap != null && prop === "rg") {
        return values.rowGap
      }
      if (values.columnGap != null && prop === "cg") {
        return values.columnGap
      }
      return null
    },
    values: {
      columns,
      itemsMinWidth,
      rowGap,
      columnGap,
    },
    propertyNames: ["c", "mw", "rg", "cg"],
  })
}
