import classNames from "classnames"
import React from "react"
import classes from "./Row.module.css"

export type GridItemsAlignment =
  | "flex-start"
  | "center"
  | "flex-end"
  | "stretch"
  | "baseline"

export type GridJustification =
  | "flex-start"
  | "center"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly"

export interface CustomRowProps {
  children: any
  noGutters?: boolean
  alignItems?: GridItemsAlignment
  justify?: GridJustification
  className?: string
  style?: React.CSSProperties
  h100?: boolean
  reverse?: boolean
}

export const CustomRow = ({
  children,
  className,
  noGutters,
  h100,
  alignItems,
  justify,
  reverse,
  style,
}: CustomRowProps) => {
  return (
    <div
      className={classNames(
        "flex flex-wrap box-border w-full",
        {
          "flex-row-reverse": reverse,
          "h-full": h100,
          "px-0": noGutters,
          [classes.spacing]: !noGutters,
        },
        className
      )}
      style={{
        alignItems,
        justifyContent: justify,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
