import React from "react"
import { getStyles } from "./functions"
import styles from "./index.module.scss"
import { GridItemProps } from "./types"

export const GridItem = (props: GridItemProps) => {
  return (
    <div
      style={{ ...getStyles(props) }}
      className={[styles.item, props.className].join(" ")}
    >
      {props.children}
    </div>
  )
}
