import React, { ReactNode } from "react"
import { GridItem } from "../GridItem"
import { getStyles } from "./functions"
import styles from "./index.module.scss"
import { GridContainerProps } from "./types"

const cycleChilds = (items: ReactNode[], children: ReactNode) => {
  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return null
    }
    if (child.type === GridItem) {
      items.push(child)
      return
    }
    if (child.type === React.Fragment) {
      cycleChilds(items, child.props.children)
      return
    }
  })
}

export const GridContainer = (props: GridContainerProps) => {
  let items: ReactNode[] = []
  cycleChilds(items, props.children)
  return (
    <div>
      <div className={styles.grid} style={{ ...getStyles(props) }}>
        {items}
      </div>
    </div>
  )
}
